import React from "react"

import { Link } from "gatsby"
import { HiHome } from "react-icons/hi"
function NotFound() {
  return (
    <div>
      <h2>404</h2>
      <p>Sorry, This page does not exist</p>
      <Link to="/">
        <HiHome fill="white" size={22} />
      </Link>
    </div>
  )
}

export default NotFound
